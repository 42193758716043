import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'event',
    component: () => import('../views/WeddingView.vue')
  },
  {
    path: '/Page2',
    name: 'Page2',
    component: () => import('../views/Page2.vue')
  },
  {
    path: '/Page3',
    name: 'Page3',
    component: () => import('../views/Page3.vue')
  },
  {
    path: '/Page4',
    name: 'Page4',
    component: () => import('../views/Page4.vue')
  },
  {
    path: '/Page5',
    name: 'Page5',
    component: () => import('../views/Page5.vue')
  },
  {
    path: '/Page6',
    name: 'Page6',
    component: () => import('../views/Page6.vue')
  },
  {
    path: '/Page7',
    name: 'Page7',
    component: () => import('../views/Page7.vue')
  },
  {
    path: '/Page8',
    name: 'Page8',
    component: () => import('../views/Page8.vue')
  },
  {
    path: '/Page9',
    name: 'Page9',
    component: () => import('../views/Page9.vue')
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'hash',
  routes
})

export default router
